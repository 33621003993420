import * as React from "react";
import TextPage, { TextBlock } from '../components/text-page';
import { useIntl } from 'gatsby-plugin-react-intl';

const KeyObjectiveRoles = () => {
  const intl = useIntl();
  const messages = intl.messages;
  const textBlocks: TextBlock[] = [{
    header: `${messages.objectives}`,
    message: (
      <ul className="ui list text-justify">
        <li className="item">{messages.message_objective_item1}</li>
        <li className="item">{messages.message_objective_item2}</li>
        <li className="item">{messages.message_objective_item3}</li>
        <li className="item">{messages.message_objective_item4}</li>
        <li className="item">{messages.message_objective_item5}</li>
      </ul>
    )
  }, {
    header: `${messages.roles}`,
    message: (
      <ul className="ui list text-justify">
        <li className="item">{messages.message_roles_item1}</li>
        <li className="item">{messages.message_roles_item2}</li>
        <li className="item">{messages.message_roles_item3}</li>
        <li className="item">{messages.message_roles_item4}</li>
        <li className="item">{messages.message_roles_item5}</li>
      </ul>
    )
  }];

  return (<TextPage locale={intl.locale} title={`${messages.key_objectives_roles}`} textBlocks={textBlocks}/>);
};

export default KeyObjectiveRoles;